// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bespoke-js": () => import("/opt/build/repo/src/pages/bespoke.js" /* webpackChunkName: "component---src-pages-bespoke-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-doors-js": () => import("/opt/build/repo/src/pages/doors.js" /* webpackChunkName: "component---src-pages-doors-js" */),
  "component---src-pages-doors-bead-and-butt-js": () => import("/opt/build/repo/src/pages/doors/bead-and-butt.js" /* webpackChunkName: "component---src-pages-doors-bead-and-butt-js" */),
  "component---src-pages-doors-bifold-js": () => import("/opt/build/repo/src/pages/doors/bifold.js" /* webpackChunkName: "component---src-pages-doors-bifold-js" */),
  "component---src-pages-doors-entrance-js": () => import("/opt/build/repo/src/pages/doors/entrance.js" /* webpackChunkName: "component---src-pages-doors-entrance-js" */),
  "component---src-pages-doors-french-js": () => import("/opt/build/repo/src/pages/doors/french.js" /* webpackChunkName: "component---src-pages-doors-french-js" */),
  "component---src-pages-doors-sliding-js": () => import("/opt/build/repo/src/pages/doors/sliding.js" /* webpackChunkName: "component---src-pages-doors-sliding-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-windows-js": () => import("/opt/build/repo/src/pages/windows.js" /* webpackChunkName: "component---src-pages-windows-js" */),
  "component---src-pages-windows-performance-flush-js": () => import("/opt/build/repo/src/pages/windows/performance-flush.js" /* webpackChunkName: "component---src-pages-windows-performance-flush-js" */),
  "component---src-pages-windows-performance-storm-js": () => import("/opt/build/repo/src/pages/windows/performance-storm.js" /* webpackChunkName: "component---src-pages-windows-performance-storm-js" */),
  "component---src-pages-windows-sliding-sash-js": () => import("/opt/build/repo/src/pages/windows/sliding-sash.js" /* webpackChunkName: "component---src-pages-windows-sliding-sash-js" */),
  "component---src-pages-windows-traditional-flush-js": () => import("/opt/build/repo/src/pages/windows/traditional-flush.js" /* webpackChunkName: "component---src-pages-windows-traditional-flush-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

